const arrowToTopButtonHandler = () => {
  const arrowButton = document.getElementById('arrow_to_top_wrapper');

  window.addEventListener('scroll', function () {
    const scrolled = window.scrollY;
    const pageHeight = document.body.scrollHeight - window.innerHeight;

    if (scrolled > pageHeight / 2) {
      arrowButton.style.opacity = '1';
    } else {
      arrowButton.style.opacity = '0';
    }
  });

  arrowButton.addEventListener('click', function () {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });
};

document.addEventListener('DOMContentLoaded', () => {
  arrowToTopButtonHandler();
});
